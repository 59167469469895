import { Alert, Box, Button, Divider, IconButton, Rating, SwipeableDrawer, TextField, Typography } from "@mui/material";
import { useState } from "react";
import Puller from "../../components/Puller/Puller";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRatingReview, deleteReview, fetchRatingReviewsDataByTypeId } from "../../redux/actions/apiActions";
import SkeletonCard from "../../components/Loaders/SkeletonCard";
import moment from "moment-timezone";
import { ArrowBack } from "@mui/icons-material";


const RatingsAndReviews = ({ type = "service", details = {} }) => {

    const [ratingValue, setRatingValue] = useState(0);
    const [showReviewsModal, setShowReviewsModal] = useState(false);
    const [customerRating, setCustomerRating] = useState(5);
    const [reviewComment, setReviewComment] = useState("");
    const [reviewsList, setReviewsList] = useState([]);
    const [totalReviews, setTotalReviews] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isAlreadyReviewed, setIsAlreadyReviewed] = useState(false);

    const ratingReviewsData = useSelector(state => state.api.ratingReviewsData);
    const ratingReviewsDataLoading = useSelector(state => state.api.ratingReviewsDataLoading);
    const userData = useSelector(state => state.auth.userData);

    const dispatch = useDispatch();

    const { height, width } = useWindowDimensions();

    const PAGE_SIZE = 5;


    const onSetRating = (rating) => {
        setCustomerRating(rating);
        setShowReviewsModal(true)
    }

    const closeReviewsModal = () => {
        setShowReviewsModal(false)
    }

    const submitRatingReview = async () => {
        let payload = {
            customerId: userData.id,
            //bookingId: bookingId,
            rating: customerRating,
            comment: reviewComment
        }
        if (type == "service") {
            payload.serviceId = details.id;
        } else if (type == "provider") {
            payload.providerId = details.id;
        }
        const status = await dispatch(addRatingReview(payload));
        if (status) {
            setIsAlreadyReviewed(true)
        }
    }

    const removeReview = async (reviewData) => {
        const success = await dispatch(deleteReview(reviewData));
        if (success) {
            setIsAlreadyReviewed(false);
        }
    }

    const loadMore = () => {
        dispatch(fetchRatingReviewsDataByTypeId(type, details?.id, PAGE_SIZE));
    }

    useEffect(() => {
        if (details?.id) {
            //console.log(">>> ", type, details?.id)
            dispatch(fetchRatingReviewsDataByTypeId(type, details?.id, PAGE_SIZE));
        }
    }, [details])

    useEffect(() => {
        if (userData) {
            dispatch(fetchRatingReviewsDataByTypeId(type, details?.id, PAGE_SIZE, true ));
        }
    }, [userData])

    useEffect(() => {

        const reviewsDataServiceId = details?.id && ratingReviewsData[details?.id]?.serviceId ? ratingReviewsData[details?.id]?.serviceId : null;
        const reviewsDataProviderId = details?.id && ratingReviewsData[details?.id]?.providerId ? ratingReviewsData[details?.id]?.providerId : null;

        //console.log("reviewsDataServiceId",reviewsDataServiceId,ratingReviewsData[details?.id])
        if ((reviewsDataServiceId && type == "service") || (reviewsDataProviderId && type == "provider")) {
            const reviewList_ = ratingReviewsData[details?.id]?.reviews ?? [];
            setReviewsList(reviewList_);
            setRatingValue(ratingReviewsData[details?.id]?.averageRating ?? 0);
            const isAlreadyReviewed = reviewList_.find(item => item?.customerId == userData?.id);
            if (isAlreadyReviewed) {
                setIsAlreadyReviewed(true);
            }
            setTotalReviews(ratingReviewsData[details?.id]?.totalNumberOfItems);
        } else {
            setReviewsList([]);
            setRatingValue(0);
            setTotalReviews(0);
        }
    }, [ratingReviewsData[details?.id]], userData)

    useEffect(() => {
        //Show/hide Loading indicator 
        setIsLoading(ratingReviewsDataLoading[details?.id]);

    }, [ratingReviewsDataLoading[details?.id]])

    //console.log("ratingReviewsData",ratingReviewsData)

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {isLoading || (!ratingReviewsData[details?.id] || ratingReviewsData[details?.id]?.lastLoadedPage < 0) ?
                    <SkeletonCard cardType="rating" />
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                        <Rating
                            name="simple-controlled"
                            value={ratingValue}
                            //readOnly
                            precision={0.5}
                            onChange={(event, newValue) => {
                                onSetRating(newValue);
                            }}
                        />
                        {totalReviews ?
                            <Typography onClick={() => setShowReviewsModal(true)} sx={{ fontSize: 12, textDecoration: "underline", cursor: 'pointer' }}>
                                {`${totalReviews} review(s)`}
                            </Typography>
                            : <></>
                        }
                    </Box>
                }
            </Box>
            <SwipeableDrawer
                anchor="bottom"
                open={showReviewsModal}
                onClose={closeReviewsModal}
                onOpen={() => setShowReviewsModal(true)}
                swipeAreaWidth={56}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: false,
                }}
                classes={{ paperAnchorBottom: "rounded-t-2xl" }}
            >
                <Box sx={{ minHeight: height * .8, p: 1 }}>
                    <Box sx={{ position: 'relative', top: 0, left: 0, right: 0 }}>
                        <IconButton aria-label="back" size="medium" sx={{ p: 2 }} onClick={closeReviewsModal} >
                            <ArrowBack fontSize="medium" />
                        </IconButton>
                        <Puller />
                    </Box>
                    <GridContainer justifyContent="center" alignItems="center" direction="row" maxWidth="md" margin="auto" sx={{ mt: 2, mb: 3 }}>
                        <GridItem xs={12}>
                            {userData?.id && !isAlreadyReviewed ?
                                <>
                                    <Divider> Rate & review this {type == "service" ? `service` : `provider`} </Divider>
                                    <GridContainer justifyContent="center" alignItems="center" sx={{ my: 3 }}>
                                        <GridItem xs={12}>
                                            <Rating
                                                name="customer-rating-contorlled"
                                                value={customerRating}
                                                precision={0.5}
                                                size="large"
                                                onChange={(event, newValue) => {
                                                    setCustomerRating(newValue);
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                id="customer-review"
                                                label="Provide your review"
                                                multiline
                                                rows={3}
                                                defaultValue=""
                                                variant="filled"
                                                fullWidth
                                                value={reviewComment}
                                                onChange={(e) => setReviewComment(e?.target?.value ?? "")}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Button variant="contained" sx={{ my: 2 }} onClick={submitRatingReview}> Submit</Button>
                                        </GridItem>
                                    </GridContainer>
                                </>
                                : null
                            }
                            <Divider> Customer Reviews</Divider>
                            {
                                reviewsList?.length ?
                                    <>
                                        {reviewsList.map((item, idx) =>
                                            <Box key={idx}>
                                                <Box sx={{ my: 2 }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <Rating
                                                            name="customer-rating-readonly"
                                                            value={item.rating}
                                                            precision={0.5}
                                                            size="small"
                                                            readOnly
                                                        />
                                                        <Typography sx={{ fontSize: 14, fontWeight: '500' }}>{item?.customerName}</Typography>
                                                    </Box>
                                                    <Typography sx={{ fontSize: 14, py: 1 }}>{item?.comment}</Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                                                        <Typography sx={{ fontSize: 12 }}>
                                                            Reviewed on {moment(item?.reviewDate?.toString().length > 10 ? item?.reviewDate : item?.reviewDate * 1000).format("D MMMM YYYY")}
                                                            {((item?.providerName && type == "service") ? ` | ${item?.providerName} ` : ((item?.serviceName && type == "provider")) ? ` | ${item?.serviceName} ` : "")}
                                                        </Typography>
                                                        {item.customerId == userData.id ?
                                                            <Button
                                                                onClick={() => removeReview(item)}
                                                                variant="outlined"
                                                                size="small"
                                                            >
                                                                Delete
                                                            </Button>
                                                            : null
                                                        }
                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Box>
                                        )}
                                        {ratingReviewsData[details?.id] && ratingReviewsData[details?.id]?.reviews?.length < ratingReviewsData[details?.id]?.totalNumberOfItems ?
                                            <Box sx={{ my: 3 }}>
                                                <Button size="small" variant="outlined" onClick={loadMore}>Load more reviews</Button>
                                            </Box>
                                            : isLoading ?
                                                <>
                                                    {Array(3).fill().map(
                                                        (item, idx) =>
                                                            <div key={idx}>
                                                                <SkeletonCard cardType="reviews" key={idx} />
                                                                <Divider />
                                                            </div>
                                                    )}
                                                </>

                                                : null
                                        }
                                    </>
                                    :
                                    <>
                                        <Alert sx={{ justifyContent: 'center', borderRadius: 3, my: 5 }} severity="warning">No reviews available</Alert>
                                    </>
                            }

                        </GridItem>
                    </GridContainer>
                </Box >
            </SwipeableDrawer >
        </>
    )
}


export default RatingsAndReviews;