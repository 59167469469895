import { useState } from "react";
import { useEffect } from "react";
import { setFilterByPinCode, setShowLocationSelector, showAlert } from "../../redux/actions/commonActions";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import getDistBetweenCordinates from "../../utils/getDistBetweenCordinates";

const LocationFilterForExistingUsers = (props) => {

    const { locationList = [], onConfirmLocation = () => { } } = props;
    const [showExistingUserPrompt, setShowExistingUserPrompt] = useState(false);
    const [promptEnabled, setPromptEnabled] = useState(false);

    const geoLocState = useSelector(state => state?.common?.geoLocationData);
    const showLocationSelector = useSelector(state => state?.common?.showLocationSelector);
    const filterByPinCode = useSelector(state => state?.common?.filterByPinCode);
    const orgDetails = useSelector(state => state.api.orgDetails);

    const dispatch = useDispatch();

    const findNearestLocationsByRadius = (geoLocation, distanceRadius = 200) => {
        return locationList.reduce((nearestLocs, currentLoc) => {
            const distanceToCurrentLoc = getDistBetweenCordinates(currentLoc?.coordinates, geoLocation?.coords);
            if (distanceToCurrentLoc !== null && distanceToCurrentLoc <= distanceRadius) {
                return [...nearestLocs, { ...currentLoc, distance: distanceToCurrentLoc }];
            }
            dispatch(showAlert("info", `Services available within a ${distanceRadius} km radius of your location are now being displayed.`, { autoHideDuration: 3000 }));
            return nearestLocs;
        }, []).sort((a, b) => a?.distance - b?.distance);
    }


    const findClosestLocation = (geoLocation) => {
        //check for pin code match 
        let matchedLocation = locationList.filter(item => item?.postCode?.trim() == geoLocation?.pinCode?.trim());
        //console.log("matchedLocation", matchedLocation);
        if (matchedLocation?.length) {
            dispatch(showAlert("info", `Services available in your area (PIN code: ${geoLocation?.pinCode}) have been displayed.`, { autoHideDuration: 3000 }));
            return matchedLocation;
        }
        //check for state match & return locations within the same state
        matchedLocation = locationList.filter(item => item?.state?.toLowerCase().trim() == geoLocation?.address?.state?.toLowerCase().trim());
        //console.log("matchedLocation", matchedLocation, locationList, geoLocation);
        if (matchedLocation?.length && matchedLocation?.length <= 10) {
            dispatch(showAlert("info", `Services available across your state (${geoLocation?.address?.state}) have been displayed.`, { autoHideDuration: 3000 }));
            return matchedLocation;
        }
        //find nearest locations within 200 km;
        let nearestLocations = findNearestLocationsByRadius(geoLocation, 200);
        if (nearestLocations?.length && nearestLocations?.length <= 10) {
            return nearestLocations;
        }
        //find nearest locations within 100 km;
        nearestLocations = findNearestLocationsByRadius(geoLocation, 100);
        if (nearestLocations?.length) {
            return nearestLocations;
        }
    }

    useEffect(() => {
        //console.log("locationList Changed", locationList);
        if (locationList?.length > 1 && filterByPinCode == null && !showLocationSelector) {
            setShowExistingUserPrompt(true);
        }
        else if (filterByPinCode && locationList?.length > 1 && geoLocState?.pinCode && !showLocationSelector) {
            const closestLocation = findClosestLocation(geoLocState);
            onConfirmLocation(closestLocation);
        }
    }, [locationList])

    useEffect(() => {
        console.log("geoLocState Changed", geoLocState);
        if (filterByPinCode && geoLocState && geoLocState?.pinCode && locationList?.length && !showLocationSelector) {
            const closestLocation = findClosestLocation(geoLocState);
            onConfirmLocation(closestLocation);
        }
    }, [geoLocState, showLocationSelector])

    useEffect(() => {
        console.log("Mounted Location Filter for existing user")
        const queryParams = new URLSearchParams(window.location.search);
        const prompt = queryParams.get('prompt');
        if (prompt === "true") {  // Show prompt flow only if prompt parameter value is true
            setPromptEnabled(prompt);
            console.log("prompt", prompt)
        } else {
            dispatch(setFilterByPinCode(false));
        }
    }, [])

    const onCloseDialog = () => {
        setShowExistingUserPrompt(false);
        dispatch(setFilterByPinCode(false));
    }
    const onConfirmDialog = () => {
        setShowExistingUserPrompt(false);
        dispatch(setShowLocationSelector({ isMandatory: true, disableAutoDetectButton: true }));
        dispatch(setFilterByPinCode(true));
    }

    const promptTitle = orgDetails?.custwebStringOverrides?.locationFilterPromptTitle ? orgDetails?.custwebStringOverrides?.locationFilterPromptTitle : 'Apply Location';
    const promptMessage = orgDetails?.custwebStringOverrides?.locationFilterPromptMessage ? orgDetails?.custwebStringOverrides?.locationFilterPromptMessage : 'Do you want to filter services based on your location?';

    return (
        <>
            {(promptEnabled === null || promptEnabled === 'true') &&
                <ConfirmationDialog
                    show={showExistingUserPrompt}
                    title={<Typography variant="subtitle2">{promptTitle}</Typography>}
                    content={promptMessage}
                    onClose={()=>setShowExistingUserPrompt(false)}
                    onConfirm={onConfirmDialog}
                    onCancel={onCloseDialog}
                    cancelLabel="No"
                    confirmLabel="Yes"
                    autoFocus={false}
                />
            }
        </>
    )
}

export default LocationFilterForExistingUsers;