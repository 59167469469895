import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
    SET_ALERT,
    SET_ASSESMENT_DATA,
    SET_FILTER_BY_PINCODE,
    SET_GEOLOCATIONDATA, SET_QUERY_PARAMS, SET_SELECTED_AVAILABILITY, SET_SHOW_LOCATION_SELECTOR, SET_SHOW_USERFORM
} from '../actions/commonActions';

const initState = {
    geoLocationData:null,
    showLocationSelector:null,
    filterByPinCode : null,
    showUserFormData: false,
    selectedAvailability: null,
    assessmentData:{},
    alertData:{},
    queryParams:null,
};
const persistConfig = {
    key: 'common',
    storage: storageSession,
    stateReconciler: autoMergeLevel2,
    blacklist: [],
};
const reducer = (state = initState, action) => {
    switch (action.type) {
        case SET_GEOLOCATIONDATA:
            return {
                ...state,
                geoLocationData: action.payload,
            };
        case SET_SHOW_USERFORM:
            return {
                ...state,
                showUserFormData: action.payload,
            };
        case SET_SHOW_LOCATION_SELECTOR:
            return {
                ...state,
                showLocationSelector: action.payload,
            };
        case SET_FILTER_BY_PINCODE:
            return {
                ...state,
                filterByPinCode: action.payload,
            };
        case SET_SELECTED_AVAILABILITY:
            return {
                ...state,
                selectedAvailability: action.payload,
            };
        case SET_ASSESMENT_DATA:
            return {
                ...state,
                assessmentData: action.payload,
            };
        case SET_ALERT:
            return {
                ...state,
                alertData: action.payload,
            };
        case SET_QUERY_PARAMS:
            return {
                ...state,
                queryParams: action.payload,
            };
        default:
            return state;
    }
};

export default persistReducer(persistConfig, reducer);
