import { useEffect, useState } from "react"
import { fetchGeoLocationByCoordinates, showAlert } from "../redux/actions/commonActions";
import { useDispatch } from "react-redux";

const useGeoLocation = () => {
    const [geoLocationData, setGeoLocationData] = useState(null);
    const dispatch = useDispatch();

    const geoLocationOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };


    const getGeoLocationPermission = async () => {
        if (navigator.geolocation) {
            const result = await navigator.permissions.query({ name: "geolocation" });
            console.log("Requesting Location result", result);
            result.onchange = fetchGeoLocation;
            return result?.state;
        } else {
            return null;
        }
    }

    const onGeoLocationSuccess = (pos) => {
        console.log(`GEOLOCATION SUCCESS `, pos);
        setGeoLocationData(pos);
    }
    const onGeoLocationError = (err) => {
        console.log(`GEOLOCATION ERROR(${err.code}): ${err.message}`);
        //alert(`GEOLOCATION ERROR(${err.code}): ${err.message}`);
    }

    const fetchGeoLocation = async () => {
        if (navigator.geolocation) {
            const permission = await getGeoLocationPermission();
            if (permission === "granted" || permission === "prompt" ) {
                navigator.geolocation.getCurrentPosition(onGeoLocationSuccess, onGeoLocationError, geoLocationOptions);
            } else {
                setGeoLocationData(null);
                dispatch(showAlert("warning","Please allow Location permissions for this website"));
            }
        } else {
            console.log("Location not supported");
            //alert("Location not supported");
            setGeoLocationData(null);
        }
    }

    useEffect(() => {
        //console.log("geoLocationData changed", geoLocationData)
        if (geoLocationData) {
            dispatch(fetchGeoLocationByCoordinates(geoLocationData))
        }
    }, [geoLocationData]);

    useEffect(() => {
        //fetchGeoLocation();
    }, []);

    return [geoLocationData,fetchGeoLocation];

}

export default useGeoLocation;